/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

let menu = [
  {
    title: 'My Account',
    children: [
      {
        id: 'a-step-01',
        icon: 'mdi-card-account-details',
        title: 'Account',
        subtitle: 'Manage Account & Profile',
        route: 'User Account'
      },
      {
        id: 'a-step-02',
        icon: 'mdi-bank',
        title: 'Billing',
        subtitle: 'History & Invoices',
        route: 'Billing'
      },
      {
        id: 'a-step-03',
        icon: 'mdi-credit-card',
        title: 'Payment Method',
        subtitle: 'Manage Payment Methods',
        route: 'Payment'
      }
    ]
  },
  {divider: true},
  {
    title: 'My Organization',
    children: [
      {
        id: 'a-step-04',
        icon: 'mdi-account-multiple',
        title: 'Users',
        subtitle: 'Manage Users Accounts',
        route: 'Users'
      }
    ]
  },
  {divider: true},
  {
    icon: 'mdi-logout',
    title: 'Log out',
    click: 'logout'
  },
];

module.exports = menu;
