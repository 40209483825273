<template>

  <v-app-bar
      id="app-bar"
      app
      dark
      height="75"
      color="#2E9EF2"
  >
    <v-btn
        class="mr-1"
        elevation="1"
        fab
        small
        @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <div class="mx-10"/>

    <!--    <v-hover-->
    <!--        v-slot="{ hover }"-->
    <!--        open-delay="50"-->
    <!--    >-->
    <!--      <v-btn-->
    <!--          depressed-->
    <!--          small-->
    <!--          class="mr-3"-->
    <!--          :color="(hover || $router.currentRoute.name === 'Dashboard')? 'primary': 'dark'"-->
    <!--          :to="{'name': 'Dashboard'}"-->
    <!--      >-->
    <!--        <v-icon class="mr-1" small>mdi-view-dashboard</v-icon>-->
    <!--        Dashboard-->
    <!--      </v-btn>-->
    <!--    </v-hover>-->

    <!--    <v-hover-->
    <!--        v-slot="{ hover }"-->
    <!--        open-delay="50"-->
    <!--    >-->
    <!--      <v-btn-->
    <!--          depressed-->
    <!--          small-->
    <!--          class="mr-3"-->
    <!--          :color="(hover || $router.currentRoute.name === 'Billing') ? 'success': 'dark'"-->
    <!--          :to="{'name': 'Billing'}"-->
    <!--      >-->
    <!--        <v-icon class="mr-1" small>mdi-bank</v-icon>-->
    <!--        Billing-->
    <!--      </v-btn>-->
    <!--    </v-hover>-->

    <span class="d-none d-md-block">
      <v-hover
          v-slot="{ hover }"
          open-delay="50"
      >
        <v-btn
            id="a-step-3"
            depressed
            small
            class="mr-3"
            :color="(hover)? 'error': 'dark'"
            href="https://corsairm360.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&status=open"
            target="_blank"
        >
          <v-icon class="mr-1" small>mdi-ticket</v-icon>
          Tickets
        </v-btn>
      </v-hover>

      <v-hover
          v-slot="{ hover }"
          open-delay="50"
      >
        <v-btn
            id="a-step-2"
            depressed
            small
            class="mr-3"
            :color="(hover)? 'warning': 'dark'"
            href="https://corsairm360.atlassian.net/servicedesk/customer/portals"
            target="_blank"
        >
          <v-icon class="mr-1" small>mdi-face-agent</v-icon>
          Support
        </v-btn>
      </v-hover>

      <v-hover
          v-slot="{ hover }"
          open-delay="50"
      >
        <v-btn
            id="a-step-1"
            depressed
            small
            class="mr-3"
            :color="(hover)? 'info': 'dark'"
            href="https://corsairm360.atlassian.net/servicedesk/customer/portal/4/article/1431437435?src=-1713164143"
            target="_blank"
        >
          <v-icon class="mr-1" small>mdi-help-circle</v-icon>
          Wiki
        </v-btn>
      </v-hover>
    </span>

    <v-spacer/>

    <v-menu
        bottom
        right
        :min-width="($screen.touch) ? 200 : 400"
        offset-y
        origin="top right"
        :close-on-click="false"
        :close-on-content-click="true"
        v-model="openTopMenu"
        transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            id="a-step-0"
            class="ml-2 mr-2 primary"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-account</v-icon>
          {{ username }}
        </v-btn>
      </template>

      <v-list
          v-if="topMenu && topMenu.length > 0"
          max-width="400"
          :tile="false"
          subheader
          flat
      >
        <template v-for="(item, i) in topMenu">
          <v-divider v-if="item.divider" :key="`divider-${i}`" class="mb-2 mt-2"/>

          <div :key="`item-${i}`" v-if="!item.divider && !item.to && item.children">
            <v-subheader>{{ item.title }}</v-subheader>
            <app-bar-item
                v-for="(childItem, j) in item.children"
                :key="j"
                link
                :to="getPage(childItem)"
            >
              <template>
                <v-list-item-icon>
                  <v-icon
                      v-text="childItem.icon"
                  ></v-icon>
                </v-list-item-icon>
                <v-list-item-content :id="childItem.id" @click="handleMenuItem(childItem, $event)">
                  <v-list-item-title v-text="childItem.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="childItem.subtitle"></v-list-item-subtitle>
                </v-list-item-content>
              </template>

            </app-bar-item>
          </div>
          <app-bar-item :key="`item-${i}`" v-if="!item.divider && !item.children" @click="handleMenuItem(item)">
            <v-list-item-icon>
              <v-icon
                  v-text="item.icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="handleMenuItem(item, $event)">
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
            </v-list-item-content>

          </app-bar-item>
        </template>
      </v-list>
    </v-menu>

  </v-app-bar>

</template>

<script>
// Components
import {VHover, VListItem} from 'vuetify/lib'

import menu from '../../../../config/topMenu';

// Utilities
import {mapMutations, mapState} from 'vuex'
import globalMixins from "../../../../mixins/globalMixins";

export default {
  name: 'DashboardCoreAppBar',

  mixins: [globalMixins],

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text lighten-4 elevation-12': hover,
                },
                style: {
                  'background-color': (hover) ? '#26C6DA' : ''
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs,
                },
              }, this.$slots.default)
            },
          },
        })
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    openTopMenu: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ModuleName: 'Dashboard',
    topMenu: []
  }),

  watch: {

    '$route': function (newRoute, oldRoute) {
      if (newRoute.name !== oldRoute.name) {
        let name = newRoute.name;
        if (newRoute.meta && newRoute.meta.breadcrumb) {
          let leaf = newRoute.meta.breadcrumb[newRoute.meta.breadcrumb.length - 1];
          if (leaf && leaf.title && leaf.active) {
            name = leaf.title;
          }
        }
        this.ModuleName = name;
      }
    }
  },

  computed: {
    ...mapState(['drawer']),

    username: function () {
      const user = this.$store.getters['auth/getUser'];
      return (user) ? user.firstName + ' ' + user.lastName : null;
    }
  },

  beforeDestroy() {
    this.topMenu = [];
  },

  async created() {
    await this.renderMenu();
  },

  methods: {

    async renderMenu() {
      let topMenu = await this.updateMenuEntries(JSON.parse(JSON.stringify(menu)));
      this.topMenu = topMenu;
    },

    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),

    logout: function () {
      if (confirm('Are you sure you want to Log out ?')) {

        const token = this.$store.getters['auth/getToken'];
        let objRes = {
          'url': '/logout',
          'method': 'get',
          'headers': {
            'env': 'portal'
          }
        };
        if (token && token.refresh_token) {
          objRes['params'] = {
            'refresh': token.refresh_token
          };
        }

        this.getSendData(objRes).then(() => {
          this.$store.dispatch('auth/clear');
          this.$cookies.remove('token');
          this.clearMessages();

          if (this.$router.currentRoute.name !== 'Login') {
            this.$router.push({name: "Login"});
          }

          setTimeout(() => {
            this.pushMessage({
              type: 'success',
              title: 'Logged Out',
              text: 'You have logged out of M360API Portal, see you back soon.'
            });
          }, 1000);
        }).catch(error => {
          console.error(error);
          this.$store.dispatch('auth/clear');
          this.$cookies.remove('token');
          this.clearMessages();

          if (this.$router.currentRoute.name !== 'Login') {
            this.$router.push({name: "Login"});
          }
        });
      }
    }
  },
}
</script>

<style lang="scss">
.v-list-item:hover {
  background-color: $secondary !important;
}
</style>